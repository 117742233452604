exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-faq-index-js": () => import("./../../../src/pages/about-us/faq/index.js" /* webpackChunkName: "component---src-pages-about-us-faq-index-js" */),
  "component---src-pages-about-us-impact-index-js": () => import("./../../../src/pages/about-us/impact/index.js" /* webpackChunkName: "component---src-pages-about-us-impact-index-js" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-about-us-team-index-jsx": () => import("./../../../src/pages/about-us/team/index.jsx" /* webpackChunkName: "component---src-pages-about-us-team-index-jsx" */),
  "component---src-pages-case-studies-driving-efficiency-index-jsx": () => import("./../../../src/pages/case-studies/driving-efficiency/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-driving-efficiency-index-jsx" */),
  "component---src-pages-case-studies-financial-flexibility-index-jsx": () => import("./../../../src/pages/case-studies/financial-flexibility/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-financial-flexibility-index-jsx" */),
  "component---src-pages-case-studies-improving-production-index-jsx": () => import("./../../../src/pages/case-studies/improving-production/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-improving-production-index-jsx" */),
  "component---src-pages-case-studies-increasing-revenue-index-jsx": () => import("./../../../src/pages/case-studies/increasing-revenue/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-increasing-revenue-index-jsx" */),
  "component---src-pages-case-studies-index-jsx": () => import("./../../../src/pages/case-studies/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-index-jsx" */),
  "component---src-pages-case-studies-overcoming-global-challenges-index-jsx": () => import("./../../../src/pages/case-studies/overcoming-global-challenges/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-overcoming-global-challenges-index-jsx" */),
  "component---src-pages-case-studies-securing-supply-index-jsx": () => import("./../../../src/pages/case-studies/securing-supply/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-securing-supply-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-cookies-index-jsx": () => import("./../../../src/pages/cookies/index.jsx" /* webpackChunkName: "component---src-pages-cookies-index-jsx" */),
  "component---src-pages-datahub-detail-index-js": () => import("./../../../src/pages/datahub-detail/index.js" /* webpackChunkName: "component---src-pages-datahub-detail-index-js" */),
  "component---src-pages-datahub-index-js": () => import("./../../../src/pages/datahub/index.js" /* webpackChunkName: "component---src-pages-datahub-index-js" */),
  "component---src-pages-disclaimer-index-jsx": () => import("./../../../src/pages/disclaimer/index.jsx" /* webpackChunkName: "component---src-pages-disclaimer-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metals-aluminum-index-jsx": () => import("./../../../src/pages/metals/aluminum/index.jsx" /* webpackChunkName: "component---src-pages-metals-aluminum-index-jsx" */),
  "component---src-pages-metals-copper-index-jsx": () => import("./../../../src/pages/metals/copper/index.jsx" /* webpackChunkName: "component---src-pages-metals-copper-index-jsx" */),
  "component---src-pages-metals-ferrous-index-jsx": () => import("./../../../src/pages/metals/ferrous/index.jsx" /* webpackChunkName: "component---src-pages-metals-ferrous-index-jsx" */),
  "component---src-pages-metals-stainless-steel-index-jsx": () => import("./../../../src/pages/metals/stainless-steel/index.jsx" /* webpackChunkName: "component---src-pages-metals-stainless-steel-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-site-map-index-jsx": () => import("./../../../src/pages/site-map/index.jsx" /* webpackChunkName: "component---src-pages-site-map-index-jsx" */),
  "component---src-pages-solutions-buying-index-jsx": () => import("./../../../src/pages/solutions/buying/index.jsx" /* webpackChunkName: "component---src-pages-solutions-buying-index-jsx" */),
  "component---src-pages-solutions-financing-index-jsx": () => import("./../../../src/pages/solutions/financing/index.jsx" /* webpackChunkName: "component---src-pages-solutions-financing-index-jsx" */),
  "component---src-pages-solutions-logistics-index-js": () => import("./../../../src/pages/solutions/logistics/index.js" /* webpackChunkName: "component---src-pages-solutions-logistics-index-js" */),
  "component---src-pages-solutions-selling-index-jsx": () => import("./../../../src/pages/solutions/selling/index.jsx" /* webpackChunkName: "component---src-pages-solutions-selling-index-jsx" */),
  "component---src-pages-terms-and-conditions-index-jsx": () => import("./../../../src/pages/terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-jsx" */)
}

